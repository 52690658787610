import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import { useFlexSearch } from 'react-use-flexsearch';
import ProgramCard from '../components/program-card';

export default function Search() {
  const [query, setQuery] = useState(null);
  const [index, setIndex] = useState(null);
  const [store, setStore] = useState(null);
  const [showResults, setShowResults] = useState(false);

  const data = useStaticQuery(graphql`
    query SearchQuery {
      searchPrograms: localSearchPrograms {
        index
        store
      }
      programs: allSanityProgram {
        nodes {
          _id
          slug {
            current
          }
          featureImage {
            ...Image
            asset {
              title
              gatsbyImageData(sizes: "25vw", aspectRatio: 1.6, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          startDate
          endDate
          title
          people {
            id
            name
          }
          publications {
            slug {
              current
            }
            title
            author
            excerpt
          }
          location
          type {
            title
          }
          series {
            title
          }
        }
      }
    }
  `)

  const results = useFlexSearch(query, data.searchPrograms.index, data.searchPrograms.store, {})

  return (
    <div
      className="type--body"
      css={css`
        grid-column: span 12;
        padding-bottom: 2rem;
        border: 1px solid var(--black);
        border-right: 0;
        border-left: 0;
        margin-top: -1.6rem;

        @media (max-width: 800px) {
          margin-bottom: var(--smallGutter);
        }
      `}
    >
      <div
        className="type--large"
        css={css`
          @media (max-width: 800px) {
            font-size: 5.5rem;
          }

          @media (max-width: 500px) {
            font-size: 4.5rem;
          }
        `}
      >
        <label
          css={css`
            overflow: hidden;
          `}
        >Search: <input
          className="type--large"
          css={css`
            line-height: 1.43;
            background-color: transparent;
            border: 0;

            @media (max-width: 800px) {
              font-size: 5.5rem;
            }

            @media (max-width: 500px) {
              font-size: 4.5rem;
              width: calc(100% - 13.5rem);
            }
          `}
          type="text"
          placeholder="Type something here"
          onKeyUp={(e) => {
            e.persist();
            
            setQuery(e.target.value);

            setShowResults(e.target.value.length > 0);
          }}
        /></label>
      </div>
      <div
        css={css`
          display: ${(showResults) ? 'grid' : 'none'};
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
          min-height: 100vh;
          margin: var(--gutter) 0;
        `}
      >
        {results.length === 0 && <div
          className="type--medium"
          css={css`
            grid-column: span 12;
          `}
        >
          No results found that match your search.
        </div>}
        {results
          .reverse()
          .map(result => data.programs.nodes.find(program => program._id === result._id))
          .sort((a, b) => (a._id > b._id) ? -1 : 1)
          .sort((a, b) => (a.startDate > b.startDate) ? -1 : 1)
          .map((program, i) => (
          <React.Fragment key={program._id}>
            <ProgramCard
              css={css`
                grid-column: span 4;

                @media (max-width: 1440px) {
                  grid-column: span 6;
                }

                @media (max-width: 700px) {
                  grid-column: span 12;
                  margin-bottom: var(--gutter);
                }
              `}
              program={program}
            />
            {program.publications.length > 0 && program.publications
              .map((publication, i) => (
                <Link
                  key={i}
                  to={`/publication/${publication.slug.current}`}
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    grid-column: span 4;
                    text-align: center;
                    text-decoration: none;
                    white-space: pre-line;
                    padding: var(--gutter);
                    border: 1px solid var(--black);

                    &:hover {
                      color: var(--white);
                      background-color: var(--black);
                    }

                    @media (max-width: 1440px) {
                      grid-column: span 6;
                    }
                  `}
                >
                  {publication.title}<br />
                  {publication.author}<br /><br />
                  {publication.excerpt}
                </Link>
              ))
            }
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
