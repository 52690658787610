import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { DateTime } from 'luxon';
import { formatDates } from '../utility';

export default class ProgramCard extends React.Component {
  constructor(props) {
    super();

    this.people = props.program.people.filter(person => person);

    this.tags = (props.program.location) ? [props.program.location.charAt(0).toUpperCase() + props.program.location.slice(1)] : [];
    this.tags = this.tags.concat(props.program.type.map((el) => el.title));
    this.tags = this.tags.concat(props.program.series.map((el) => el.title));

    this.future = (new Date(props.program.startDate).valueOf() > new Date().valueOf());
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props !== nextProps) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <Link
        to={`/program/${this.props.program.slug.current}`}
        css={css`
          display: ${(this.future) ? ((this.props.showImages) ? 'none' : 'flex') : 'block'};
          align-items: center;
          justify-content: center;
          position: relative;
          text-align: center;
          text-decoration: none;
          border: ${(this.future) ? '1px dotted var(--black)' : ''};
          border-radius: ${((this.props.program.location === 'online' && this.future)) ? `6rem` : `0`};
          padding: ${(this.future) ? 'calc(var(--gutter) * 2) var(--gutter)' : '0'};
          opacity: ${(this.future) ? 0.6 : 1};

          &:hover {
            opacity: 1;

            img {
              mix-blend-mode: screen;
            }
          }
        `}
        className={this.props.className}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          {(!this.future && this.props.program.featureImage) &&
            <div
              css={css`
                background-color: var(--pink);
                border-radius: ${(this.props.program.location === 'online') ? `6rem` : `0`};
                line-height: 0;
              `}
            >
              <Image
                {...this.props.program.featureImage}
                css={css`
                  background-color: var(--pink);
                  border-radius: ${(this.props.program.location === 'online') ? `6rem` : `0`};
                `}
                width={990}
                height={620}
                style={{
                  width: '100%',
                }}
                alt={(this.props.program.featureImage.asset.altText) ? this.props.program.featureImage.asset.altText : this.props.program.title}
              />
            </div>
          }
          {!this.props.program.featureImage &&
            <div
              css={css`
                padding-bottom: 62.5%;
                background-color: var(--20Brown);
              `}
            />
          }
          <div
            className="type--outline--small"
            css={css`
              display: ${(this.props.showImages) ? 'block' : 'none'};
              position: absolute;
              right: var(--gutter);
              bottom: var(--gutter);
              left: var(--gutter);
            `}
          >
            {(this.props.program?.featureImage?.asset.description) ? this.props.program.featureImage.asset.description : this.props.program.title}
          </div>
        </div>
        <div
          css={css`
            display: ${(!this.props.showImages) ? 'block' : 'none'}
          `}
        >
          {this.future && <div
            className="type--mono"
            css={css`
              position: absolute;
              top: ${(this.props.program.location === 'online') ? '2rem' : '1rem'};
              left: ${(this.props.program.location === 'online') ? '3.1rem' : '2.1rem'};
              width: 100%;
              text-align: left;
              margin: 1.1rem 0 1.3rem;
            `}
          >
            Opening {DateTime.fromISO(this.props.program.startDate).toFormat('d MMM')}
          </div>}
          <div
            className="type--mono"
            css={css`
              position: ${(this.future) ? 'absolute' : 'static'};
              left: 0;
              width: 100%;
              bottom: 1rem;
              margin: 1.1rem 0 1.3rem;
            `}
          >
            {this.tags.length > 0 && this.tags
              .map((tag, i) => <span key={i}>{tag}</span>)
              .reduce((prev, curr) => [prev, ', ', curr])
            }
          </div>
          <h3
            className="type--medium"
            css={css`
              margin-bottom: 0;
            `}
          >{formatDates(this.props.program.startDate, this.props.program.endDate)}</h3>
          <h2
            className="type--medium"
            css={css`
              margin-bottom: 4.5rem;
            `}
          >{this.props.program.title}</h2>
          <h3
            className="type--medium"
            css={css`
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              padding-bottom: 0.5rem;
              margin-bottom: 0;
              overflow: hidden;
            `}
          >
            {this.people.length > 0 && this.people
              .map(person => <span key={person.id}>{person.name}</span>)
              .reduce((prev, curr) => [prev, ', ', curr])
            }
          </h3>
        </div>
      </Link>
    );
  }
}
