import { DateTime } from 'luxon';

function areDatesEqual(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

export function isOpen(dates, exceptions) {
  let isOpen = false;
  let until = null;

  const days = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  dates.forEach((date) => {
    if (checkDay(days[date.day])) {
      if (checkTime(parseInt(pad(date.openTime.replace(':', '')), 10), parseInt(pad(date.closeTime.replace(':', '')), 10))) {
        isOpen = true;
        until = `${normalizeTime(date.closeTime)}${(parseInt(date.closeTime.replace(':', ''), 10) < 1200) ? 'am' : 'pm'}`;
      }
    }
  });

  exceptions.forEach((dateRange) => {
    for (let d = new Date(dateRange.startDate); d <= new Date(dateRange.endDate); d.setDate(d.getDate() + 1)) {
      if (areDatesEqual(d, new Date())) {
        isOpen = false;
      }
    }
  });

  return {
    open: isOpen,
    until: until,
  }

  function normalizeTime(time) {
    time = parseInt(time, 10);

    return (time > 12) ? time - 12 : time;
  }

  function checkDay(day) {
    if (new Date().getDay() === day) {
      return true;
    } else {
      return false;
    }
  }

  function checkTime(start, end) {
    const current = parseInt(`${pad(new Date().getHours())}${pad(new Date().getMinutes())}`, 10);
    if (current >= start && current <= end) return true;
  }

  function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  function pad(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }
}

export function formatDates(dateOne, dateTwo) {
  let dateString = '';

  dateOne = DateTime.fromISO(dateOne);

  if (!dateTwo) {
    dateString = `${dateOne.toFormat('d MMM y')}`;
  } else {
    dateTwo = DateTime.fromISO(dateTwo);

    if (dateOne.year === dateTwo.year) {
      if (dateOne.month === dateTwo.month) {
        dateString = `${dateOne.toFormat('d')}–${dateTwo.toFormat('d MMM y')}`;
      } else {
        dateString = `${dateOne.toFormat('d MMM')}–${dateTwo.toFormat('d MMM y')}`;
      }
    } else {
      dateString = `${dateOne.toFormat('d MMM y')}–${dateTwo.toFormat('d MMM y')}`;
    }
  }
  
  return dateString;
}

export function formatDateTimes(dateTimeOne, dateTimeTwo) {
  let dateTimeString = '';

  dateTimeOne = DateTime.fromISO(dateTimeOne);

  if (!dateTimeTwo) {
    const dateTimeOneTime = `${dateTimeOne.toFormat('h')}${(dateTimeOne.toFormat('mm') === '00') ? `` : `:${dateTimeOne.toFormat('mm')}`}${(dateTimeOne.toFormat('H') < 12) ? 'am' : 'pm'}`;

    dateTimeString = `${dateTimeOne.toFormat('d MMM y')}, ${dateTimeOneTime}`;
  } else {
    dateTimeTwo = DateTime.fromISO(dateTimeTwo);

    const dateTimeOneTime = `${dateTimeOne.toFormat('h')}${(dateTimeOne.toFormat('mm') === '00') ? `` : `:${dateTimeOne.toFormat('mm')}`}${(dateTimeOne.toFormat('H') < 12) ? 'am' : 'pm'}`;
    const dateTimeTwoTime = `${dateTimeTwo.toFormat('h')}${(dateTimeTwo.toFormat('mm') === '00') ? `` : `:${dateTimeTwo.toFormat('mm')}`}${(dateTimeTwo.toFormat('H') < 12) ? 'am' : 'pm'}`;


    if (dateTimeOne.year === dateTimeTwo.year) {
      if (dateTimeOne.month === dateTimeTwo.month) {
        if (dateTimeOne.day === dateTimeTwo.day) {
          dateTimeString = `${dateTimeTwo.toFormat('d MMM y')}, ${dateTimeOneTime}–${dateTimeTwoTime}`;
        } else {
          dateTimeString = `${dateTimeOne.toFormat('d')}–${dateTimeTwo.toFormat('d MMM y')}, ${dateTimeOneTime}–${dateTimeTwoTime}`;
        }
      } else {
        dateTimeString = `${dateTimeOne.toFormat('d MMM')}–${dateTimeTwo.toFormat('d MMM y')}, ${dateTimeOneTime}–${dateTimeTwoTime}`;
      }
    } else {
      dateTimeString = `${dateTimeOne.toFormat('d MMM y')}–${dateTimeTwo.toFormat('d MMM y')}, ${dateTimeOneTime}–${dateTimeTwoTime}`;
    }
  }
  
  return dateTimeString;
}
